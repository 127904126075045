import React from "react"
//import SimpleMap from "../components/SimpleMap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import {
  companyName,
  companyAdd,
  companyTel,
  companyTelEn,
  companyFax,
  companyMail,
  companyHp,
  companyAddEn,
} from "../components/configs/ShortCode"
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps"

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 37.42695482405854, lng: 127.09370557379422 }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: 37.42695482405854, lng: 127.09370557379422 }}
        />
      )}
    </GoogleMap>
  ))
)

//const WrappedMap = withScriptjs(withGoogleMap(Map))

const Contact = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="contactWrap">
      <div className="sectionWrap mapWrap">
        <div style={{ width: "100%", height: "100%" }}>
          <Map
            isMarkerShown
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDPEoyvDuQWQgMK5SwVXebkxG3CKNkUOHs&language=ko&region=KR"
            }
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </div>
      </div>
      <div className="sectionWrap addWrap">
        <div className="addText">
          <p className="compName">{companyName}</p>
          <p className="compInfo">
            {companyAdd}
            <br />
            {companyAddEn}
            <br />
            <br />
            네비에서 <strong>반도아이비밸리</strong> (성남 수정구 고등동) 검색
            후 이동하시면 편리합니다.
            <br />
            <br />
            <Link to="/">{companyHp}</Link>
            <br />
          </p>
        </div>
        <ul>
          <li>
            <p>
              <img src="" alt="" />
            </p>
            <p className="addKind">Email</p>
            <p className="addValue">
              {companyMail}
              <br />
            </p>
          </li>
          <li>
            <p>
              <img src="" alt="" />
            </p>
            <p className="addKind">Phone</p>
            <p className="addValue">
              {companyTel}
              <br />
              {companyTelEn}
            </p>
          </li>
          <li>
            <p>
              <img src="" alt="" />
            </p>
            <p className="addKind">Fax</p>
            <p className="addValue">
              {companyFax}
              <br />
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default Contact
